.profile-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 10%;
  min-height: fit-content;
}
.profile-parent {
  display: flex;
  align-items: center;
  color: aliceblue;
}
.profile-details-name {
  font-size: 24px;
  font-family: "Poppins SemiBold";
}
.profile-details-role {
  display: flex;
  flex-direction: column;
  margin: 14px 0 24px 0;
}
.profile-details-role h1 {
  font-size: 40px;
  font-family: "cursive";
}
.primary-text {
  color: var(--white);
}
.highlighted-text {
  color: var(--red);
}
.profile-role-tagline {
  font-size: 19px;
  margin: 5px 0 0 0;
  font-family: "Poppins Light";
}
.profile-options .highlighted-btn {
  margin: 0 0 0 28px;
  border-radius: 12px solid white;
}
.profile-options .highlighted-btn:hover {
  margin: 0 0 0 28px;
  background-color: cornsilk;
  color: #111;
}
.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 380px;
  width: 380px;
  margin: 0 0px 0 100px;
}
.profile-picture-background {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-image: url("../../../assets/Home/Profile.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.profile-picture-background:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}

.colz {
    display: inline-table;
    margin: 10px;
}

.header-logo {
  font-size: 50px;
  font-family: "Poppins ExtraBold";
  color: var(--white);
}

/* new icons */
.icons {
	display: flex;
	column-gap: 25px;
}
.icon {
	display: inline-flex;
	width: 60px;
	height: 60px;
	text-decoration: none;
	font-size: 26px;
	color: #fff;
	border-radius: 50%;
	outline: 2px solid #fff;
	transition-property: outline-offset, outline-color, background-color;
	transition-duration: 0.25s;
}
.icon:hover {
	outline-offset: 4px;
}
.icon i {
	margin: auto;
}
.icon:hover i {
	animation: shake 0.25s;
}
/* .icon--instagram:hover {
	background-image: radial-gradient(
		circle at 30% 107%,
		#fdf497 0%,
		#fdf497 5%,
		#fd5949 45%,
		#d6249f 60%,
		#285aeb 90%
	);
	outline-color: #a02d76;
} */
.icon--instagram:hover {
  color: black;
  background-color: var(--red);
	outline-color: var(--red);
}
.icon--linkedin:hover {
  color: black;
	background-color: var(--red);
	outline-color: var(--red);
}
.icon--facebook:hover {
  color: black;
	background-color: var(--red);
	outline-color: var(--red);
}
.icon--github:hover {
  color: black;
	background-color: var(--red);
	outline-color: var(--red);
}
@keyframes shake {
	10% {
		transform: rotate(15deg);
	}
	20% {
		transform: rotate(-15deg);
	}
	30% {
		transform: rotate(15deg);
	}
	40% {
		transform: rotate(-15deg);
	}
}


/*IPAD PRO RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
  .profile-picture {
    margin: 0 0px 0 80px;
  }
  .profile-details-name {
    font-size: 20px;
  }
  .profile-details-role .primary-text {
    font: 12px;
  }
  .profile-options .btn {
    width: 120px;
    padding: 11px 0;
    z-index: 700;
  }
}
@media only screen and (max-width: 910px) {
  .profile-picture {
    margin: 0 0 0 4px;
    height: 320px;
    width: 320px;
  }
}
@media only screen and (max-width: 810px) {
  .profile-picture {
    margin: 0 !important;
  }
  .profile-parent {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
  }
  .proflie-details {
    margin: 25px 0 0;
  }
  .profile-options {
    margin: 0 0 15px;
  }
  .profile-options .primary-btn{
    margin: 0 10px;
  }
  .profile-options .highlighted-btn {
    margin: 0 !important;
  }
  .profile-details-name,
  .profile-details-role {
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .profile-picture {
    height: 275px;
    width: 275px;
    margin-top: -90px;
  }
  .proflie-details {
    width: 95%;
  }
}
